.services {
    position: relative;
    width: 100%;
    padding: rem(40) rem(60);

    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__title {
            font-size: rem(24);
            font-weight: 700;
            line-height: 32px;
        }

        &__button {
            text-align: center;
            width: rem(316);
            padding: rem(12) rem(30);
            background: #00A7B5;
            font-size: rem(16);
            font-weight: 600;
            line-height: 26px;
            border-radius: rem(8);
            color: #fff;
            cursor: pointer;
        }
    }

    &-content {
        display: grid;
        grid-template-columns: repeat(4, minmax(rem(315), 1fr));
        grid-auto-rows: auto;
        margin-top: rem(20);
        grid-gap: rem(20);
    }

    &-block{
        display: flex;
        flex-direction: column;
        min-height: rem(210);
        border-radius: rem(16);
        gap: rem(10);
        padding: rem(20);
        background: #ffffff;

        &__image {
            padding: rem(15);
            background: #00A7B51A;
            border-radius: 50%;
            max-width: rem(66);
            max-height: rem(66);
        }

        &__title {
            font-size: rem(20);
            font-weight: 600;
            line-height: 28px;
        }

        &__category {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: rem(100);
            width: fit-content;
            padding: rem(6) rem(12);
            background: #0000000D;
            font-size: rem(12);
            font-weight: 500;
            line-height: 20px;
            color: #00000080;
        }
    }


    &-create {
        padding-bottom: rem(78);
        &-content {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
        }

        &-block {
            width: rem(650);
            margin-top: rem(46);
            background: #ffffff;
            border-radius: rem(16);
            padding: rem(20);
        }

        &-form {
            display: flex;
            flex-direction: column;
            gap: rem(20);

            & .form-image {
                margin-bottom: 0;
            }

            &__title {
                font-size: rem(16);
                font-weight: 600;
            }

            &__line {
                width: 100%;
                height: rem(1);
                background:  #0000001A;
            }

            &__hashtags {
                &__warning {
                    margin-top: rem(8);
                    font-size: rem(12);
                    font-weight: 500;
                    line-height: 20px;
                    color: #00A7B5;
                }

                &__values {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    gap: rem(8);
                    margin-top: rem(8);

                    &__item {
                        display: flex;
                        align-items: center;
                        gap: rem(10);
                        background: #00A7B50D;
                        padding: rem(4) rem(16);
                        border-radius: rem(100);

                        & p {
                            font-size: rem(14);
                            font-weight: 500;
                            line-height: 22px;
                            color: #00A7B5;
                        }
                    }

                    &__delete {
                        display: flex;
                        align-items: center;
                        max-width: rem(12);
                        max-height: rem(12);
                        cursor: pointer;
                    }
                }
            }

            &-field {
                display: flex;
                flex-direction: column;
                gap: rem(8);

                &__label {
                    font-size: rem(12);
                    font-weight: 600;
                    line-height: 20px;
                    opacity: .6;
                }

                &__block {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background: #00000008;
                    padding: rem(12) rem(12) rem(12) rem(16);
                    border-radius: rem(8);
                }

                &__input {
                    width: 100%;
                    background: none;
                    border: none;
                    outline: none;

                    &__right {
                        display: flex;
                        align-items: center;
                        gap: rem(10);
                    }

                    &__button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                    }
                }

                &__button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    background: #007BFF0D;
                    padding: rem(8) rem(15);
                    height: rem(50);
                    border-radius: rem(8);
                    margin-top: rem(2);
                    cursor: pointer;
                }
            }

            &__employees {
                display: flex;
                //align-items: center;
                flex-direction: column;
                border-radius: rem(16);
                background: rgba(0, 0, 0, 0.031372549);
                padding: rem(20);

                &__list {
                    border-radius: 1.25rem;
                    padding: 0.625rem;
                    gap: 0.625rem;
                    display: flex;
                    flex-direction: column;
                    margin-bottom: rem(20);

                    .layout-vacation-modal__block {
                        padding: 0.625rem;
                        border-radius: 1rem;
                        background: #FFFFFF;
                    }
                }

                &__bottom {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                &__addButton {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    background: #00A7B51A;
                    padding: rem(8);
                    border-radius: rem(8);
                    height: fit-content;
                    width: fit-content;
                }
            }
        }

        &-modal {
            padding: rem(20);

            &-statuses {
                max-width: rem(650) !important;
                width: rem(650) !important;
            }

            &__header {
                display: flex;
                align-items: center;
                justify-content: space-between;

                &__title {
                    font-size: rem(24);
                    font-weight: 700;
                    line-height: 29.26px;
                }

                &__close {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
            }

            &__content {
                margin-top: rem(20);
            }

            &__buttons {
                display: flex;
                flex-direction: column;
                gap: rem(10);
                margin-top: rem(30);

                &__item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    font-size: rem(16);
                    font-weight: 600;
                    line-height: 26px;
                    border-radius: rem(8);
                    height: rem(50);
                    cursor: pointer;
                }

                &__add {
                    color: #fff;
                    background: #00A7B5;
                }

                &__delete {
                    color: #FF0000;
                    background: #FF00001A;
                }
            }
        }
    }

    &-education {
        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: rem(16);
            padding: 0 rem(60);

            &__left {
                display: flex;
                align-items: center;
                gap: rem(20);

                & h1 {
                    font-size: rem(32);
                    font-weight: 700;
                    line-height: 38px;
                }
            }
            &__right {
                width: rem(315);
            }
        }

        &-content {
            margin-top: rem(30);
            height: 100%;
            &__top {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                padding: 0 rem(60);

                &__block {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: rem(14);
                    font-weight: 500;
                    line-height: 22px;
                    color: rgba(0, 0, 0, 0.8);
                    opacity: 0.5;
                    height: rem(42);
                    cursor: pointer;
                }

                &__block.active {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: rem(14);
                    font-weight: 600;
                    line-height: 22px;
                    opacity: 1;
                    background: rgba(0, 0, 0, 0.03);
                    border-radius: rem(16) rem(16) rem(0) rem(0);

                }
            }
        }

        &-wrapper {
            width: 100%;
            background: rgba(0, 0, 0, 0.03);
            height: 100%;
            padding: rem(30) rem(60);
            min-height: calc(100vh - 20rem);

            &__header {
                display: flex;
                justify-content: space-between;
                gap: rem(90);

                &__button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: rem(50);
                    border-radius: rem(8);
                    color: #fff;
                    width: rem(201);
                    background: #00A7B5;
                    font-size: rem(16);
                    font-weight: 600;
                    line-height: 26px;
                    align-self: end;
                }
            }

            &__buttons {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                gap: rem(20);
                height: rem(76);

                &__block {
                    display: flex;
                    flex-direction: column;
                    gap: rem(8);
                    width: 100%;

                    & .form-select,  {
                        background: #fff;
                        height: rem(48);
                    }

                    & .calendar-block__date-picker {
                        background: #fff;
                        border-radius: rem(8);
                        height: rem(48);

                        & input {
                            background: none;
                        }
                    }

                    & p {
                        font-size: rem(12);
                        font-weight: 600;
                        line-height: 20px;
                        opacity: .6;
                    }
                }
            }
        }
    }

    &-applications {
        display: flex;
        flex-direction: column;
        margin-top: rem(20);

        &__header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: rem(12);
            font-weight: 600;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.5);
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            padding: rem(15) rem(30);
            background-color: #fff;
            border-radius: rem(16) rem(16) 0 0;

            & .column {
                width: 20%;
            }
            //
            //& .colApp {
            //    width: 22.86%;
            //}
            //
            //& .colExecutor {
            //    width: 15.88%;
            //}
            //
            //& .colDate {
            //    width: 14.29%;
            //}
            //
            //& .colStatus {
            //    width: 10.16%;
            //}
        }

        &__body {
            background: #ffffff;
            border-radius: 0 0 rem(16) rem(16);
            &__block {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: rem(13) rem(30);
                width: 100%;

                & .width {
                    width: 20%;
                }

                &__id {
                    font-size: rem(14);
                    font-weight: 500;
                    line-height: 20px;
                    color: #00A7B5;
                }

                &__date {
                    font-size: rem(14);
                    font-weight: 500;
                    line-height: 20px;
                }

                &__file {
                    font-size: rem(14);
                    font-weight: 500;
                    line-height: 20px;
                    color: #00A7B5;
                    cursor: pointer;
                }

                &__status {
                    & p {
                        width: fit-content;
                        background: rgba(0, 0, 0, 0.05);
                        padding: rem(7) rem(12);
                        border-radius: rem(8);
                        font-size: rem(14);
                        font-weight: 500;
                        line-height: 20px;
                    }
                }
            }
        }

        &__download {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            text-align: center;
            padding-top: rem(20);

            & p {
                width: fit-content;
                font-size: rem(14);
                font-weight: 600;
                line-height: 20px;
                color: #00A7B5;
                cursor: pointer;
            }
        }
    }

    &-acceptance {
        margin-top: rem(20);

        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: rem(30);
            font-size: rem(12);
            font-weight: 600;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.5);
            border-bottom: rem(2) solid rgba(0, 0, 0, 0.05);
            padding: rem(15) rem(30);
            background-color: #fff;
            border-radius: rem(16) rem(16) 0 0;

            & .colId {
                width: 10%;
            }

            & .colDate {
                width: 15%;
            }


            & .colStatus {
                width: 10%;
            }

            & .colFile {
                width: 10%;
            }
        }

        &__content {
            background: #ffffff;
            border-radius: 0 0 rem(16) rem(16);

            & div {
                &:last-child {
                    border-bottom: unset;
                }
            }
        }

        &__block {
            display: flex;
            justify-content: space-between;
            gap: rem(30);
            border-bottom: rem(1) solid rgba(0, 0, 0, 0.05);
            padding: rem(13) rem(30);

            &__id {
                width: 10%;
                font-size: rem(14);
                font-weight: 500;
                line-height: 20px;
                color: #00A7B5;
            }

            &__date {
                width: 15%;
                font-size: rem(14);
                font-weight: 500;
                line-height: 20px;
            }

            &__admin {
                display: flex;
                align-items: center;
                height: fit-content;
                gap: rem(8);

                &__image {
                    width: rem(35);
                    height: rem(35);
                }

                &__name {
                    width: 100%;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    font-size: rem(14);
                    font-weight: 500;
                    line-height: 20px;
                }
            }

            &__users {
                display: flex;
                flex-direction: column;
                gap: rem(20);
            }

            &__user {
                display: flex;
                align-items: center;
                gap: rem(20);

                &__left {
                    display: flex;
                    align-items: center;
                    gap: rem(8);

                    & img {
                        width: rem(35);
                        height: rem(35);
                    }

                    & h5 {
                        width: 100%;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        font-size: rem(14);
                        font-weight: 500;
                        line-height: 20px;
                    }
                }
            }

            &__file {
                width: 10%;
                font-size: rem(14);
                font-weight: 500;
                line-height: 20px;
                color: #00A7B5;
                cursor: pointer;
                height: fit-content;
            }

            &__status {
                display: flex;
                justify-content: end;
                gap: rem(10);
                width: 10%;

                &__item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #0000000D;
                    border-radius: rem(8);
                    min-width: rem(34);
                    min-height: rem(34);
                    width: rem(34);
                    height: rem(34);
                    cursor: pointer;
                }
            }
        }
    }

    &-history {
        margin-top: rem(20);

        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: rem(12);
            font-weight: 600;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.5);
            border-bottom: rem(2) solid rgba(0, 0, 0, 0.05);
            padding: rem(15) rem(30);
            background-color: #fff;
            border-radius: rem(16) rem(16) 0 0;

            & .colId {
                width: 10%;
            }

            & .colDate {
                width: 15%;
            }

            & .colAdmin {
                width: 25%;
            }

            & .colUser {
                width: 25%;
            }

            & .colStatus {
                width: 10%;
            }
        }

        &__content {
            background: #ffffff;
            border-radius: 0 0 rem(16) rem(16);

            & div {
                &:last-child {
                    border-bottom: unset;
                }
            }
        }

        &__block {
            display: flex;
            justify-content: space-between;
            border-bottom: rem(1) solid rgba(0, 0, 0, 0.05);
            padding: rem(13) rem(30);

            &__id {
                width: 10%;
                font-size: rem(14);
                font-weight: 500;
                line-height: 20px;
                color: #00A7B5;
            }

            &__date {
                width: 15%;
                font-size: rem(14);
                font-weight: 500;
                line-height: 20px;
            }

            &__admin {
                display: flex;
                align-items: center;
                height: fit-content;
                gap: rem(8);
                width: 25%;

                &__image {
                    width: rem(35);
                    height: rem(35);
                }

                &__name {
                    font-size: rem(14);
                    font-weight: 500;
                    line-height: 20px;
                }
            }

            &__users {
                display: flex;
                flex-direction: column;
                gap: rem(20);
                width: 25%;
            }

            &__user {
                display: flex;
                align-items: center;
                gap: rem(20);

                &__left {
                    display: flex;
                    align-items: center;
                    gap: rem(8);

                    & img {
                        width: rem(35);
                        height: rem(35);
                    }

                    & h5 {
                        font-size: rem(14);
                        font-weight: 500;
                        line-height: 20px;
                    }
                }
            }

            &__status {
                width: 10%;
                &__button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: rem(100);
                    border-radius: rem(8);
                    height: rem(34);
                    font-size: rem(14);
                    font-weight: 500;
                    line-height: 20px;
                    color: #fff;

                    &.accepted {
                        background: #00A7B5;
                    }

                    &.rejected {
                        background: #FF5454;
                    }
                }
            }
        }
    }
}