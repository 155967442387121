.layout.scrolled {
    @media (max-width: 1430px) {
        padding-right: 0;
    }
}

.layout {
    display: flex;
    gap: 1.25rem;
    width: 100%;
    padding: 1.875rem 3.75rem;
    height: 100%;

    @media (max-width: 1430px) {
        padding-right: 0;
    }

    @media (max-width: 1020px) {
        gap: 1.875rem;
        padding: 0;
        flex-direction: column;
    }

    &-left {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 19.75rem;
        gap: 0.625rem;
        min-height: 17.2rem;

        @media (max-width: 1020px) {
            padding: 0 1rem;
            width: 100%;
            min-height: unset;
            gap: 0;
        }
    }

    &-right {
        position: relative;
        display: flex;
        flex-direction: column;
        width: calc(100% - 21rem);

        @media (max-width: 1430px) {
            padding: 0 ;
        }

        @media (max-width: 1020px) {
            gap: 1.25rem !important;
            padding: 0 0 6.25rem 0;
            width: 100%;
        }

        &-block {
            display: flex;
            flex-direction: column;
            gap: rem(20);

            &__header {
                display: flex;
                align-items: center;
                justify-content: space-between;

                &__right {
                    display: flex;
                    align-items: center;
                    gap: rem(20);
                }

                @media (max-width: 1020px) {
                    padding: 0 rem(16);
                }

                &__title {
                    font-weight: 700;
                    font-size: 2rem;
                    line-height: 2.375rem;

                    @media (max-width: 1020px) {
                        font-size: rem(20);
                        font-weight: 700;
                        line-height: rem(28);
                    }
                }

                &__link {
                    display: flex;
                    align-items: center;
                    color: #000;
                    cursor: pointer;


                    & h4 {
                        font-weight: 600;
                        font-size: 1rem;
                        line-height: 1.5rem;

                        @media (max-width: 1020px) {
                            font-size: rem(14);
                        }
                    }
                }

                &__addButton {
                    display: flex;
                    align-items: center;
                    gap: rem(10);
                    border: none;
                    background: #fff;
                    border-radius: 0.5rem;
                    padding: 0.5rem 1.875rem;
                    cursor: pointer;

                    & p {
                        color: #000;
                        font-weight: 600;
                        font-size: rem(14);
                        line-height: rem(24);
                    }
                }
            }
        }
    }

    &-calendar {
        display: flex;
        min-width: 363px;
        justify-content: space-between;
        gap: 1rem;

        @media (max-width: 1020px) {
            overflow: scroll;
            padding: 0 1rem;
        }

        &::-webkit-scrollbar {
            display: none;
        }

        &__date {
            position: relative;
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 0.625rem;
        }

        &__dateDay {
            display: flex;
            align-items: center;
            width: 2.75rem;
            height: 2.75rem;
            background: #ffffff;
            border-radius: 100%;
            cursor: pointer;

            & p {
                width: 100%;
                text-align: center;
                font-size: 1rem;
                line-height: 1.5rem;
                font-weight: 600;
            }
        }

        &__dateWeek {
            font-weight: 500;
            font-size: 0.75rem;
            line-height: 1.2rem;
            color: #000;
        }
    }

    &-birthdaysMobile {

        &__birthdaysBody {
            margin-top: rem(30);

            .feed-controls {
                padding: 0;
            }
        }

        &__birthdaysList {
            display: grid;
            grid-auto-rows: 1fr;
            grid-gap: 0.625rem;
            margin-top: rem(10);

            @media (max-width: 1020px) {
                grid-template-columns: repeat(5, minmax(rem(108), 1fr));
            }

            @media (max-width: 870px) {
                grid-template-columns: repeat(4, minmax(rem(108), 1fr)) !important;
            }

            @media (max-width: 570px) {
                grid-template-columns: repeat(3, minmax(rem(108), 1fr)) !important;
            }


            & > div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 0.625rem;
                background: #ffffff;
                border-radius: 1rem;
                transition: 0.5s all ease;
                cursor: pointer;
                padding: rem(15) rem(10);

                & > img {
                    width: rem(60);
                    height: rem(60);
                    border-radius: 1.25rem;
                }

                & > p {
                    text-align: center;
                    text-wrap: wrap;
                    width: 6.25rem;
                    font-size: rem(14);
                    font-weight: 600;
                    line-height: rem(20);

                }
            }
        }
    }

    &-birthdays {
        background: #fff;
        width: 100%;
        padding: rem(10) rem(20);
        border-radius: 0.5rem;

        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &-image {
                width: rem(50);
                height: rem(50);
                transform: rotate(-90deg);
            }
        }

        &__titles {
            & h1 {
                font-size: 1rem;
                font-weight: 700;
                line-height: 1.375rem;
            }

            & p {
                font-size: rem(12);
                font-weight: 600;
                line-height: rem(20);
            }
        }

        &__content {
            display: grid;
            grid-template-columns: repeat(4, minmax(3.375rem, 1fr));
            grid-auto-rows: 1fr;
            grid-gap: 0.5rem;
            width: 100%;
            margin-top: 1.25rem;
        }

        &__block {
            & img {
                width: rem(50);
                height: rem(50);
                border-radius: 50%;
            }

            &__noneImage {
                display: flex;
                align-items: center;
                justify-content: center;
                background: #00A7B50D;
                width: rem(50);
                height: rem(50);
                border-radius: 50%;
                font-size: rem(20);
                font-weight: 700;
                line-height: 26px;
            }
        }

        &__button {
            width: 100%;
            height: 3rem;
            border: none;
            text-align: center;
            background: #F2FBFB;
            font-size: 1rem;
            font-weight: 600;
            line-height: rem(24);
            margin-top: rem(15);
            border-radius: 0.5rem;
        }
    }


    &-badges {
        display: flex;
        flex-direction: column;
        gap: 1.25rem;
        width: 100%;
        background-color: #ffffff;
        border-radius: 1rem;
        padding: 1.25rem;

        @media (max-width: 1020px) {
            margin-top: rem(10);
        }

        &__title {
            display: flex;
            font-size: 1rem;
            font-weight: 700;
            line-height: 1.375rem;

            div {
                display: flex;
                align-items: center;
                gap: 0.2rem;

                svg {
                    font-weight: 700;
                    max-width: 0.8rem;
                    margin-top: -0.3rem;
                }
            }
        }

        &__more {
            font-size: 0.75rem;
            font-weight: 600;
            line-height: 1.375rem;
            color: #000;
            cursor: pointer;
            transition: color 0.2s linear;
            margin-left: auto;
        }

        &__list {
            display: grid;
            grid-template-columns: repeat(4, minmax(3.375rem, 1fr));
            grid-auto-rows: 1fr;
            grid-gap: 0.625rem;
            width: 100%;
        }

        &__item {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 3.375rem;
            height: 3.375rem;
            cursor: pointer;
            background-color: #fafafa;
            border-radius: 50%;

            img {
                width: 2.5rem;
                height: 2.5rem;
                border-radius: 50%;
            }
        }

        &__itemCount {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 0;
            right: 0;
            width: 1.188rem;
            height: 1.125rem;
            font-size: 0.75rem;
            font-weight: 600;
            color: #ffffff;
            background-color: #FA1919;
            border-radius: 50%;
        }
    }


    &-profile {
        position: relative;
        padding: 1.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 0.625rem;
        background: #ffffff;
        border-radius: 1rem;

        &__buttons {
            display: flex;
            flex-direction: column;
            gap: rem(10);
            margin-top: rem(20);
            width: 100%;

            & button {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: rem(8);
                border: none;
                height: rem(48);
                gap: rem(10);
                font-size: rem(16);
                font-weight: 600;
                line-height: rem(24);

                & p {
                    font-size: rem(16);
                    font-weight: 600;
                    line-height: rem(24);
                }


                & svg {
                    width: rem(22);
                    height: rem(22);
                }
            }

            &__qrCodeButton {
                background: #F2FBFB;
            }

            &__adaptationButton {
                background: #00A7B5;
                color: #fff;
            }
        }

        &__avatar {
            &__noneImage {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 5rem;
                height: 5rem;
                font-size: rem(20);
                font-weight: 700;
                line-height: 26px;
            }
        }

        &__avaBox {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 5rem;
            height: 5rem;
            margin: 0 auto 0.625rem auto;
            border-radius: 1.25rem;
            box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
            background-color: #FFFFFF;

            & img {
                width: 5rem;
                height: 5rem;
            }
        }

        &__nameBlock {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 0.313rem;

            & h1 {
                text-align: center;
                font-weight: 700;
                font-size: 1.25rem;
                line-height: 1.625rem;
                width: 17.188rem;
                text-wrap: wrap;
                word-wrap: break-word;
            }

            & div {
                display: flex;
                align-items: center;
                gap: 0.625rem;

                & p {
                    font-weight: 500;
                    font-size: 0.875rem;
                    line-height: 1.375rem;
                }
            }
        }

        &__businessCard {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.625rem;
            width: 100%;
            height: 100%;
            font-size: 1rem;
            font-weight: 600;
            background-color: #F2FBFB;
            border: none;
            border-radius: 0.5rem;
            cursor: pointer;
            transition: color 0.2s linear;
            padding: 0.813rem;
            margin-top: 0.625rem;

            svg {
                width: 1.375rem;
                height: 1.375rem;
                color: #007BFF;
            }
        }
    }


    &-status {
        display: flex;
        flex-direction: column;
        gap: 0.625rem;
        background: #ffffff;
        padding: 1.25rem;
        border-radius: 1rem;

        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h3 {
                font-weight: 600;
                font-size: 0.875rem;
                line-height: 1.375rem;
            }

            p {
                font-weight: 600;
                font-size: 0.75rem;
                line-height: 1.25rem;
                color: #1958FA;
            }
        }

        &__content {
            display: grid;
            grid-template-columns: repeat(4, minmax(3.5rem, 1fr));
            grid-auto-rows: 1fr;
            grid-gap: 0.625rem;
            width: 100%;
        }

        &__item {
            position: relative;
            display: block !important;
            max-width: fit-content;
            margin-right: 1rem;
            background-color: #FAFAFA;
            cursor: pointer;
            user-select: none;
        }

        &__circle {
            width: 3.5rem;
            height: 3.5rem;
            border-radius: 50%;
        }
    }


    &-stats {
        position: relative;
        display: flex;
        gap: 0.625rem;

        @media (max-width: 1020px) {
            margin-top: rem(10);
        }

        &__block {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1.25rem;
            width: 100%;
            background: #ffffff;
            padding: 1.25rem;
            border-radius: 1rem;

            p {
                font-weight: 700;
                font-size: 1.25rem;
                line-height: 1.75rem;
            }
        }

        &__circle {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            padding: 0.5rem;
        }
    }


    &-thanks {
        display: flex;
        justify-content: space-between;
        gap: 1.25rem;
        width: 100%;
        background: linear-gradient(90deg, #0069B5 0%, #00A7B5 100%);
        border-radius: 1rem;
        padding: 1.25rem;
        cursor: pointer;

        @media (max-width: 1020px) {
            max-width: 100%;
            width: 100%;
            margin-top: rem(20);
            //margin: 0 1rem;
        }

        &__title {
            display: flex;
            align-items: center;
            gap: 0.625rem;
            font-size: 1.125rem;
            font-weight: 700;
            line-height: 1.25rem;
            color: #ffffff;

            svg {
                min-width: 2rem;
                min-height: 2rem;
                color: #ffffff;
                opacity: 0.5;
            }
        }

        &__list {
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        &__item {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2.5rem;
            height: 2.5rem;
            background-color: #CCD9E0;
            border: 0.125rem solid #ffffff;
            border-radius: 50%;

            &:not(:first-child) {
                margin-left: -0.938rem;
            }

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }

        &__name {
            font-size: 1.125rem;
            font-weight: 600;
            color: #ffffff;
        }

        &__count {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2.5rem;
            height: 2.5rem;
            font-size: 0.75rem;
            font-weight: 600;
            color: #000;
            background-color: #ffffff;
            border-radius: 50%;
            margin-left: -0.938rem;
        }

        &__more {
            font-size: 1rem;
            font-weight: 600;
            line-height: 1.625rem;
            text-transform: none;
            color: #ffffff;
            background-color: #ffffff;
            transition: color 0.2s linear, background-color 0.2s linear;
            padding: 0.563rem 1rem;
            margin: auto auto 0 0;

            &:hover {
                background-color: rgba(#fff, 0.2);
            }
        }
    }

    &-banner {
        background: #fff;
        width: 100%;
        border-radius: 0.5rem;

        @media (max-width: 1020px) {
            margin-top: rem(10);
        }

        &__image {
            border-top-right-radius: rem(8);
            border-top-left-radius: rem(8);
            background-size: cover !important;
            width: 100%;
            height: rem(170);

            @media (max-width: 1020px) {
               height: rem(210);
                //margin: 0 1rem;
            }
        }

        &__content {
            padding: rem(20);
            border-bottom-left-radius: rem(8);
        }

        &__desc {
            & p {
                font-size: rem(12);
                font-weight: 500;
                line-height: rem(18);
            }

            & h1 {
                font-size: 1rem;
                font-weight: 600;
                line-height: rem(24);

            }
        }

        &__button {
            margin-top: 1.25rem;
            width: 100%;
            height: rem(50);
            border: none;
            background: #00A7B5;
            border-radius: 0.5rem;
            font-size: 1rem;
            font-weight: 600;
            line-height: rem(26);
            color: #fff;
        }
    }

    &-skills {
        position: relative;
        padding: 1.25rem;
        display: flex;
        flex-direction: column;
        background: #ffffff;
        border-radius: 1rem;
        gap: 0.625rem;

        &__title {
            font-weight: 700;
            font-size: 1.25rem;
            line-height: 1.75rem;
        }

        &__content {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            gap: 0.625rem;
        }

        &__block {
            padding: 0.5rem 1rem;
            background: #007bff0d;
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.5rem;
            border-radius: 6.25rem;
        }
    }


    &-job {
        position: relative;
        padding: 1.25rem;
        display: flex;
        flex-direction: column;
        background: #ffffff;
        border-radius: 1rem;

        &__title {
            font-weight: 700;
            font-size: 1.25rem;
            line-height: 1.75rem;
        }

        &__content {
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        &__block {
            display: flex;
            flex-direction: column;
            padding: 0.625rem 0.625rem 0.625rem 0;
            box-shadow: 0px -1px 0px 0px #0000001A inset;

            p {
                font-weight: 500;
                font-size: 0.75rem;
                line-height: 1.25rem;
                opacity: 0.5;
            }

            h3 {
                font-weight: 600;
                font-size: 1rem;
                line-height: 1.5rem;

            }
        }
    }


    &-contest {
        display: flex;
        flex-direction: column;
        padding: 1.25rem;
        background: #ffffff;
        border-radius: 1rem;
        gap: 1.25rem;

        @media (max-width: 1020px) {
            margin: 0 1rem;
        }

        &__header {
            display: flex;
            flex-direction: column;
            gap: 0.313rem;

            @media (max-width: 1020px) {
                flex-direction: row;
                align-items: center;
            }

            & img {
                width: 3.375rem;
                height: 3.375rem;
            }

            & h1 {
                font-weight: 700;
                font-size: 1.25rem;
                line-height: 1.75rem;

                @media (max-width: 1020px) { // Adjust the breakpoint as needed for 'sm'
                    font-weight: 600;
                    font-size: 1rem;
                    line-height: 1.375rem;
                }
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            margin-right: 1rem;
        }

        &__block {
            display: flex;
            align-items: center;
            gap: 1rem;

            & input[type="checkbox"]:checked {
                border-radius: 50%;
                width: 1.5rem;
                height: 1.5rem;
                display: flex;
                border: 2px solid #1958FA33;
                cursor: pointer;
                position: relative;
                justify-content: center;
                align-items: center;

                &::after {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    border: 0.1rem solid #fff;
                    content: '';
                    position: absolute;
                    background: #1958FA33;
                    z-index: 10;
                }
            }

            & input[type="checkbox"] {
                border-radius: 50%;
                width: 1.5rem;
                height: 1.5rem;
                -webkit-appearance: none;
                display: flex;
                border: 2px solid #1958FA33;
                cursor: pointer;
            }

            & h3 {
                font-weight: 500;
                font-size: 0.875rem;
                line-height: 1.5rem;
            }
        }

        &__button {
            background: #00A7B5;
            text-align: center;
            border-radius: 0.5rem;
            padding: 0.875rem 1.875rem;
            border: none;
            color: #ffffff;
            font-size: rem(16);
            font-weight: 600;
            line-height: rem(22);
            cursor: pointer;
        }
    }

    &-links {
        background: #fff;
        border-radius: 0.5rem;
        width: 100%;

        &-mobile {
            display: flex;
            flex-direction: column;
            padding: 0 rem(15);

            .feed-controls {
                padding: 0;
            }

            &__button {
                display: flex;
                align-items: center;
                justify-content: center;
                background: #fff;
                width: 100%;
                height: rem(50);
                font-size: rem(14);
                font-weight: 600;
                line-height: rem(22);
                border-radius: rem(8);
            }
        }


        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: rem(13) rem(20);

            h1 {
                font-size: rem(16);
                font-weight: 700;
                line-height: rem(24);
            }

            &__more {
                font-size: rem(12);
                font-weight: 600;
                line-height: rem(22);
                cursor: pointer;
            }
        }

        &__content {
            display: grid;
            grid-template-columns: repeat(3, minmax(rem(105), 1fr));
            width: 100%;
            padding: 0 0 rem(15) 0;

            @media (max-width: 1020px) {
                grid-template-columns: repeat(2, minmax(rem(167), 1fr));
                grid-gap: rem(10);
                margin-top: rem(10);
            }
        }

        &__block {
            display: flex;
            align-items: center;
            flex-direction: column;
            padding: rem(10) rem(15);
            gap: rem(10);

            &__noneImage {
                font-size: rem(20);
                font-weight: 700;
                line-height: 26px;
            }

            @media (max-width: 1020px) {
                background: #fff;
                border-radius: rem(8);
            }

            &__image {
                display: flex;
                align-items: center;
                justify-content: center;
                width: rem(66);
                height: rem(66);
                background: #00A7B50D;
                border-radius: 50%;

                & img {
                    width: rem(30);
                    height: rem(30);
                    border-radius: 50%;
                }
            }


            & h1 {
                font-size: rem(12);
                font-weight: 600;
                line-height: rem(20);
                text-align: center;
            }
        }
    }

    &-vacation {
        position: relative;
        padding: 1.25rem;
        display: flex;
        flex-direction: column;
        background: #ffffff;
        border-radius: 1rem;


        @media (max-width: 1020px) {
            margin-top: rem(10);
        }

        &-mobile {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #FFFFFF;
            padding: rem(14) rem(20);
            border-radius: rem(16);
            margin-top: rem(10);
            height: rem(60);

            &__dialogHeader {
                display: flex;
                align-items: center;
                justify-content: space-between;

                & div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            &__dialogTitle {
                font-size: rem(20);
                font-weight: 700;
            }

            &__title {
                font-size: rem(16);
                font-weight: 600;
                line-height: rem(24);
            }

            &__day {
                font-size: rem(24);
                font-weight: 700;
                line-height: rem(32);
                color: #00A7B5;
            }
        }

        &__header {
            display: flex;
            flex-direction: column;

            @media (max-width: 1020px) {
                margin-top: rem(20);
            }

            & h5 {
                font-weight: 600;
                font-size: 0.875rem;
                line-height: 1.375rem;
            }

            & h1 {
                font-weight: 700;
                font-size: 2rem;
                line-height: 2.5rem;
                color: #00A7B5;

                @media (max-width: 768px) {
                    font-size: 1.5rem;
                    line-height: 1.75rem;
                }
            }
        }

        &__dateWrapper {
            display: flex;
            flex-direction: column;
            gap: 0.375rem;
            margin-top: rem(20);

            & h3 {
                font-weight: 500;
                font-size: 0.875rem;
                line-height: 1.375rem;
            }
        }

        &__dateContent {
            display: flex;
            align-items: center;

            & p {
                padding: 0.25rem 0.75rem;
                background: #00000008;
                border-radius: 6.25rem;
                color: #00A7B5;
                font-weight: 600;
                font-size: 0.875rem;
                line-height: 1.375rem;

                @media (max-width: 768px) {
                    font-weight: 500;
                    font-size: 0.75rem;
                    line-height: 1rem;
                }
            }
        }

        &__body {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: rem(20);

            & p {
                font-weight: 500;
                font-size: 0.875rem;
                line-height: 1.5rem;
            }

            & h3 {
                font-weight: 700;
                font-size: 1rem;
                line-height: 1.5rem;
            }
        }

        &__buttons {
            width: 100%;
            margin-top: rem(20);

            & button {
                text-align: center;
                padding: 0.75rem;
                width: 100%;
                border-radius: 0.5rem;
                font-weight: 600;
                font-size: 1rem;
                line-height: 1.625rem;
                border: none;
                cursor: pointer;
            }
        }

        &-replacer {
            margin-top: rem(20);

            &__title {
                font-size: rem(12);
                font-weight: 600;
                line-height: rem(20);
                opacity: .6;
            }

            &__content {
                padding: rem(15);
                background: #00000008;
                border-radius: rem(16);
                margin-top: rem(10);


                &__top {
                    display: flex;
                    justify-content: space-between;

                    & img {
                        width: rem(60);
                        height: rem(60);
                    }
                    
                    & svg {
                        cursor: pointer;
                    }
                }

                &__desc {
                    display: flex;
                    flex-direction: column;
                    margin-top: rem(20);
                    gap: rem(5);

                    & h1 {
                        font-size: rem(16);
                        font-weight: 600;
                        line-height: rem(24);
                    }
                }

                &__contact {
                    display: flex;
                    align-items: center;
                    gap: rem(10);

                    & p {
                        font-size: rem(14);
                        font-weight: 500;
                        line-height: rem(22);
                    }
                }
            }
        }

        &__replaceButton {
            background: rgba(0, 0, 0, 0.05);
            margin-bottom: rem(10);
        }

        &__calendarButton {
            background: #F2FBFB;
        }


        &-modal {
            @media (max-width: 768px) {
                height: 100%;
            }

            &__header {
                @media (max-width: 768px) {
                    width: 100%;
                    position: fixed;
                    left: 0;
                    height: rem(120);
                    background: #FFFFFF;
                    z-index: 1;
                    padding: rem(20);
                    margin-top: rem(-20);
                }

            }

            &__search {
                display: flex;
                align-items: center;
                background: #0000000D;
                border-radius: rem(100);
                gap: rem(10);
                padding: rem(10);
                width: 100%;
                height: rem(44);
                margin-bottom: rem(10);

                @media (max-width: 768px) {
                    margin-top: rem(40);
                }

                & input {
                    width: 100%;
                    border: none;
                    background: none;

                    &::placeholder {
                        font-size: rem(14);
                        font-weight: 500;
                        line-height: rem(22);
                        opacity: .5;
                    }

                    &:focus {
                        outline: none;
                    }
                }
            }

            &__title {
                display: flex;
                align-items: center;
                position: absolute;
                gap: rem(20);
                top: rem(15);
                font-size: rem(20);
                font-weight: 600;
                line-height: rem(26);

                @media (max-width: 768px) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    text-align: center;
                    margin-left: rem(-20);
                }


            }

            &__linearButton {
                @media (max-width: 768px) {
                    width: 100%;
                    position: fixed;
                    bottom: 0;
                    left: 0;
                    padding: rem(20);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: linear-gradient(0deg, #FFFFFF 49.53%, rgba(255, 255, 255, 0) 100%);
                    height: rem(130);
                }
            }

            &__button {
                width: 100%;
                font-size: rem(16);
                font-weight: 600;
                line-height: rem(25);
                height: rem(50);
                background: #00A7B5;
                border: none;
                color: #fff;
                border-radius: rem(8);
                margin-top: rem(20);

                @media (max-width: 768px) {
                    margin-top: 0;
                    z-index: 9999;
                }
            }

            &__content {
                @media (max-width: 768px) {
                    max-height: 100%;
                    overflow-y: scroll;
                    padding: rem(100) 0 rem(100) 0 ;
                    //margin-bottom: 100px;
                    height: 100%;
                    z-index: 1;
                }
            }

            &__block {
                display: flex;
                align-items: center;
                gap: rem(20);
                padding: rem(10) 0;

                &__right {
                    display: flex;
                    align-items: center;
                    gap: rem(20);

                    & img {
                        width: rem(60);
                        height: rem(60);
                    }
                }

                &__desc {
                    display: flex;
                    flex-direction: column;
                    gap: rem(4);

                    & h1 {
                        font-size: rem(16);
                        font-weight: 600;
                        line-height: rem(20);
                    }

                    &__position {
                        font-size: rem(14);
                        font-weight: 500;
                        line-height: rem(18);
                    }

                    &__department {
                        font-size: rem(12);
                        font-weight: 500;
                        line-height: rem(16);
                        opacity: .6;
                    }
                }

                & input[type="checkbox"]:checked {
                    border-radius: 50%;
                    min-width: rem(24);
                    min-height: rem(24);
                    width: rem(24);
                    height: rem(24);
                    display: flex;
                    background: #00A7B5;
                    border: 1px solid #00A7B5;
                    cursor: pointer;
                    position: relative;
                    justify-content: center;
                    align-items: center;

                    &::after {
                        content: '';
                        position: absolute;
                        width: rem(12);
                        height: rem(1.5);
                        background: #fff;
                        transform: rotate(-45deg);
                        margin-top: rem(1);
                        margin-left: rem(5);
                        border-radius: rem(8);
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        margin-top: rem(5);
                        margin-left: rem(-7);
                        width: rem(7);
                        height: rem(1.5);
                        border-radius: rem(8);
                        background: #fff;
                        transform: rotate(45deg);
                    }
                }

                & input[type="checkbox"] {
                    border-radius: 50%;
                    min-width: rem(24);
                    min-height: rem(24);
                    width: rem(24);
                    height: rem(24);
                    -webkit-appearance: none;
                    display: flex;
                    border: 2px solid rgba(0, 0, 0, 0.2);
                    cursor: pointer;
                }
            }
        }
    }


    &-job {
        position: relative;
        padding: 1.25rem;
        display: flex;
        flex-direction: column;
        background: #ffffff;
        border-radius: 1rem;
        @media (max-width: 768px) {
            margin-right: 1rem;
            margin-top: 1.875rem;
        }

        &__title {
            font-weight: 700;
            font-size: 1.25rem;
            line-height: 1.75rem;
            @media (max-width: 768px) {
                font-size: 1.125rem;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        &__block {
            display: flex;
            flex-direction: column;
            padding: 0.625rem 0.625rem 0.625rem 0;
            box-shadow: 0px -1px 0px 0px #0000001a inset;

            p {
                font-weight: 500;
                font-size: 0.75rem;
                line-height: 1.25rem;
                opacity: 0.5;
            }

            h3 {
                font-weight: 600;
                font-size: 1rem;
                line-height: 1.5rem;
                @media (max-width: 768px) {
                    font-size: 0.875rem;
                }
            }
        }
    }


    &-skills {
        position: relative;
        padding: 1.25rem;
        display: flex;
        flex-direction: column;
        background: #ffffff;
        border-radius: 1rem;
        gap: 0.625rem;
        @media (max-width: 768px) {
            margin-right: 1rem;
            margin-top: 1.875rem;
        }

        &__title {
            font-weight: 700;
            font-size: 1.25rem;
            line-height: 1.75rem;
        }

        &__content {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            gap: 0.625rem;
        }

        &__block {
            padding: 0.5rem 1rem;
            background: #007bff0d;
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.5rem;
            border-radius: 6.25rem;
            @media (max-width: 768px) {
                font-size: 0.75rem;
            }
        }
    }


    &-congratulations {
        display: flex;
        flex-direction: column;
        gap: rem(20);
        padding: rem(20);
        background: #fff;
        border-radius: rem(16);

        @media (max-width: 1020px) {
            margin-top: rem(10);
        }

        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            & h1 {
                font-size: rem(14);
                font-weight: 600;
                line-height: rem(22);
            }

            & p {
                font-size: rem(14);
                font-weight: 600;
                line-height: rem(22);
                color: #00A7B5;
            }
        }

        &__content {
            display: flex;
            align-items: center;
        }

        &__block {
            width: rem(56);
            height: rem(56);
            border-radius: rem(140);
            margin-left: rem(-10);
            border: rem(1.4) solid #fff;

            & img {
                border-radius: 50%;
            }
        }

        &__block.first {
            margin-left: 0;
        }
    }

    &-favorites {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: rem(15);
        background: linear-gradient(90deg, #0069B5 0%, #00A7B5 100%);
        border-radius: rem(16);

        @media (max-width: 1020px) {
            height: rem(60);
            margin-top: rem(10);
        }

        &__left {
            display: flex;
            align-items: center;
            gap: rem(10);

            & svg {
                & g {
                    opacity: 1;
                }
                & path {
                    fill:  #fff;
                    stroke: #fff;
                }
            }

            & h1 {
                font-size: rem(18);
                font-weight: 700;
                line-height: rem(20);
                color: #fff;
            }
        }

        &__count {
            display: flex;
            align-items: center;
            justify-content: center;
            width: rem(40);
            height: rem(40);
            background: #fff;
            font-size: rem(14);
            font-weight: 600;
            line-height: rem(22);
            border-radius: 50%;
        }
    }
}

.inner__layout {
    padding: rem(68) 0 0;
    min-height: calc(100vh - 5.5rem);
}

.layout-inner {
    display: flex;
    flex-direction: column;
    padding: rem(78) rem(60);

    @media (max-width: 1020px) {
        padding: rem(15) rem(15) rem(50) rem(15);
        margin-top: rem(40);
    }

    &__routes {
        display: flex;
        align-items: center;
        gap: rem(10);
        padding-top: rem(78);
        padding-left: rem(60);

        &__link {
            display: flex;
            align-items: center;
            gap: rem(10);
            font-size: rem(14);
            font-weight: 500;
            line-height: rem(22);
            opacity: 0.5;

            & p {
                font-size: rem(14);
                font-weight: 500;
                line-height: rem(22);
            }
        }

        &__link.active {
            display: flex;
            align-items: center;
            gap: rem(10);
            font-size: rem(14);
            font-weight: 500;
            line-height: rem(22);
            opacity: 1;

            p {
                font-size: rem(14);
                font-weight: 500;
                line-height: rem(22);
            }
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__title {
            display: flex;
            align-items: center;
            gap: rem(20);

            & svg {
                cursor: pointer;
            }
        }

        & h1 {
            font-size: rem(32);
            font-weight: 700;
            line-height: rem(38);
        }

        &__selects {
            display: flex;
            gap: rem(20);
        }
    }

    &__content {
        display: flex;
        gap: rem(20);
        margin-top: rem(30);
    }

    &__left {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 19.75rem;
        gap: 0.625rem;
        min-height: 17.2rem;
    }
}
